import React, { useState, useEffect } from "react";
import styled from "styled-components";
import useForm from "react-hook-form";
import * as masks from "../../../helpers/masks";
import Lockr from "lockr";
import { FireWebToCase } from "../../../services/SalesForceService";
import CorreiosService from "../../../services/CorreiosService";

const Form = (props) => {
  const { register, handleSubmit, errors, clearError } = useForm();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [clientId, setClientId] = useState("");
  const [contractId, setContractId] = useState("");
  const [addressFields, setAddressFields] = useState({
    zipCode: "",
    state: "",
    city: "",
    neighborhood: "",
    road: "",
    number: "",
    complement: "",
  });

  const phone = props.values.contactPhone;
  const valuesJson = () => {
    let description;
    let description2;

    if (props.values.fields[0].registrationData.value === "Endereço") {
      const addressFields = `Endereço: ${props.values.fields[0].addressFields.road}, ${props.values.fields[0].addressFields.number} - ${props.values.fields[0].addressFields.neighborhood}, ${props.values.fields[0].addressFields.city} - ${props.values.fields[0].addressFields.state}, ${props.values.fields[0].addressFields.zipCode} - complemento: ${props.values.fields[0].addressFields.complement}`;
      description = addressFields;
    } else if (props.values.fields[0].registrationData.value === "Telefone") {
      const newPhone = `novo telefone: ${props.values.fields[0].newPhone}`;
      description = newPhone;
    } else if (props.values.fields[0].registrationData.value === "E-mail") {
      const newEmail = `novo email: ${props.values.fields[0].newEmail}`;
      description = newEmail;
    } else if (
      props.values.fields[0].registrationData.value === "Utilização do veículo"
    ) {
      const vehicleUse = `Utilização do veículo: ${props.values.fields[0].vehicleUse.value}`;
      description = vehicleUse;
    } else if (props.values.fields[0].registrationData.value === "Outros") {
      const anotherMotive = `Outros: ${props.values.fields[0].anotherMotive}`;
      description = anotherMotive;
    }

    if (props.values.showAdditionalField) {
      if (props.values.fields[1].registrationData.value === "Endereço") {
        const addressFields = `Endereço: ${props.values.fields[1].addressFields.road}, ${props.values.fields[1].addressFields.number} - ${props.values.fields[1].addressFields.neighborhood}, ${props.values.fields[1].addressFields.city} - ${props.values.fields[1].addressFields.state}, ${props.values.fields[1].addressFields.zipCode} - complemento: ${props.values.fields[1].addressFields.complement}`;
        description2 = addressFields;
      } else if (props.values.fields[1].registrationData.value === "Telefone") {
        const newPhone = `novo telefone: ${props.values.fields[1].newPhone}`;
        description2 = newPhone;
      } else if (props.values.fields[1].registrationData.value === "E-mail") {
        const newEmail = `novo email: ${props.values.fields[1].newEmail}`;
        description2 = newEmail;
      } else if (
        props.values.fields[1].registrationData.value ===
        "Utilização do veículo"
      ) {
        const vehicleUse = `Utilização do veículo: ${props.values.fields[1].vehicleUse.value}`;
        description2 = vehicleUse;
      } else if (props.values.fields[1].registrationData.value === "Outros") {
        const anotherMotive = `Outros: ${props.values.fields[1].anotherMotive}`;
        description2 = anotherMotive;
      }

      return `${description}, ${description2}`;
    } else {
      return `${description}`;
    }
  };

  const onSubmit = async (data) => {
    try {
      const valuesDescription = valuesJson();

      const reason = "Alteração de Dados Cadastrais";
      const subject = "Alteração de Dados Cadastrais";
      const description = `Telefone para contato: ${phone}, ${valuesDescription}`;

      const formData = {
        reason,
        subject,
        "00N6f00000Febwz": contractId,
        "00NN5000001Lr7C": clientId,
        description,
      };

      delete data["registrationDataCheck-0"];

      const formDataJson = JSON.stringify(formData);

      props.values.setLoading(true);

      await FireWebToCase(JSON.parse(formDataJson));

      props.values.setLoading(false);
      props.proceed();
    } catch (error) {
      console.error("Ocorreu um erro ao enviar os dados:", error);
      props.values.setLoading(false);
    }
  };

  useEffect(() => {
    async function loadData() {
      let value;
      let clientData;

      if (props.nCtrId) {
        clientData = Lockr.get("ClientData");

        value = clientData.customerCars.filter((contracts) => {
          return contracts.contractInfo.nCtrId === props.nCtrId;
        });

        Lockr.set("ClientData", {
          IdCustomerSelectedCar: value[0].value,
          customerSelectedCar: value[0],
        });

        const customerSelectedCar = clientData.customerSelectedCar;
        const contractInfo = customerSelectedCar.contractInfo;
        setClientId(contractInfo.bpName);
        setContractId(contractInfo.nCtrId);
      } else {
        clientData = Lockr.get("ClientData");
        const customerSelectedCar = clientData.customerSelectedCar;
        const contractInfo = customerSelectedCar.contractInfo;
        setClientId(contractInfo.bpName);
        setContractId(contractInfo.nCtrId);
      }
    }

    loadData();
  }, [props.nCtrId]);

  useEffect(() => {
    let areAllFieldsFilled = true;

    Object.keys(addressFields).forEach((key) => {
      if (key !== "complement" && addressFields[key].trim() === "") {
        areAllFieldsFilled = false;
      }
    });
    setIsSubmitDisabled(!areAllFieldsFilled);
  }, [addressFields]);

  const handleAddressChange = (e, index) => {
    const { name, value } = e.target;

    if (name === "zipCode") {
      const zipCodeValue = value.replace(/\D/g, "");
      const formattedValue = zipCodeValue.replace(/^(\d{5})(\d{3})$/, "$1-$2");

      setAddressFields((prevState) => ({
        ...prevState,
        [name]: formattedValue,
      }));
      props.handleChangeAddress(e, index);
    } else {
      setAddressFields((prevState) => ({ ...prevState, [name]: value }));
      props.handleChangeAddress(e, index);
    }

    setIsSubmitDisabled(false);
  };

  const load = async (zipCode, index) => {
    props.values.setLoading(true);

    const zipCodeValue = zipCode.replace(/\D/g, "");
    const formattedValue = zipCodeValue.replace(/^(\d{5})(\d{3})$/, "$1-$2");

    handleAddressChange(
      {
        target: {
          name: "zipCode",
          value: formattedValue,
        },
      },
      index
    );

    try {
      const result = await CorreiosService.getAddress(zipCodeValue);

      if (result) {
        handleAddressChange(
          {
            target: {
              name: "neighborhood",
              value: result.Bairro,
            },
          },
          index
        );

        handleAddressChange(
          {
            target: {
              name: "city",
              value: result.Municipio,
            },
          },
          index
        );

        handleAddressChange(
          {
            target: {
              name: "state",
              value: result.UF,
            },
          },
          index
        );

        handleAddressChange(
          {
            target: {
              name: "road",
              value: result.Logradouro,
            },
          },
          index
        );
      }
      props.values.setLoading(false);
    } catch (error) {
      props.values.setLoading(false);
      console.error("Error fetching address:", error);
    }
  };

  const formatPhoneNumber = (value) => {
    value = value.replace(/\D/g, "");
    value = value.replace(/^(\d{2})(\d)/g, "($1) $2");
    value = value.replace(/(\d)(\d{4})$/, "$1-$2");
    return value;
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        {props.values.fields.map((field, index) => (
          <>
            {index === 0 && (
              <div key={index}>
                <label>
                  <span>O QUE DESEJA ALTERAR?</span>
                  <select
                    name={`registrationDataCheck`}
                    ref={register({ required: true })}
                    className="loovi-select"
                    value={field.registrationData.value}
                    onChange={(e) => {
                      setIsSubmitDisabled(true);
                      const val = e.target.value;
                      props.handleSelectChangeData(
                        { value: val, label: val },
                        index
                      );
                      if (field.registrationData.value !== "")
                        return clearError(`registrationDataCheck`);
                    }}
                  >
                    {field.registrationsData.map((area, idx) => (
                      <option key={idx} value={area.value}>
                        {area.label}
                      </option>
                    ))}
                  </select>
                  {errors[`registrationDataCheck`] && (
                    <span className="loovi-msg-error">Selecione uma opção</span>
                  )}
                </label>
                {field.registrationData.value === "Endereço" && (
                  <>
                    <h4
                      className="loovi-color-grey-2"
                      style={{ marginTop: "1rem" }}
                    >
                      Informe seu novo endereço
                    </h4>
                    <div className="address-cep">
                      <label>
                        <span>CEP</span>
                        <input
                          type="text"
                          name={`zipCode`}
                          onChange={(e) => handleAddressChange(e, index)}
                          ref={register({
                            required: true,
                            validate: (input) => {
                              const value = input.replace(/[\D]/g, "");
                              return value.replace(/\d{5}-\d{3}/);
                            },
                          })}
                          onBlur={(e) => load(e.target.value, index)}
                          className={
                            errors[`zipCode`]
                              ? "loovi-field loovi-field-error"
                              : "loovi-field"
                          }
                          maxLength="9"
                        />
                        {errors[`zipCode`] && (
                          <span className="loovi-msg-error">Insira o CEP</span>
                        )}
                      </label>
                      <label>
                        <span>ESTADO</span>
                        <input
                          type="text"
                          name={`state`}
                          value={addressFields.state}
                          onChange={(e) => handleAddressChange(e, index)}
                          ref={register({ required: true })}
                          className={
                            errors[`state`]
                              ? "loovi-field loovi-field-error"
                              : "loovi-field"
                          }
                        />
                        {errors[`state`] && (
                          <span className="loovi-msg-error">
                            Insira o estado
                          </span>
                        )}
                      </label>
                    </div>
                    <label>
                      <span>CIDADE</span>
                      <input
                        type="text"
                        name={`city`}
                        value={addressFields.city}
                        onChange={(e) => handleAddressChange(e, index)}
                        ref={register({ required: true })}
                        className={
                          errors[`city`]
                            ? "loovi-field loovi-field-error"
                            : "loovi-field"
                        }
                      />
                      {errors[`city`] && (
                        <span className="loovi-msg-error">Insira a cidade</span>
                      )}
                    </label>
                    <label>
                      <span>BAIRRO</span>
                      <input
                        type="text"
                        name={`neighborhood`}
                        value={addressFields.neighborhood}
                        onChange={(e) => handleAddressChange(e, index)}
                        ref={register({ required: true })}
                        className={
                          errors[`neighborhood`]
                            ? "loovi-field loovi-field-error"
                            : "loovi-field"
                        }
                      />
                      {errors[`neighborhood`] && (
                        <span className="loovi-msg-error">Insira o bairro</span>
                      )}
                    </label>
                    <label>
                      <span>RUA</span>
                      <input
                        type="text"
                        name={`road`}
                        value={addressFields.road}
                        onChange={(e) => handleAddressChange(e, index)}
                        ref={register({ required: true })}
                        className={
                          errors[`road`]
                            ? "loovi-field loovi-field-error"
                            : "loovi-field"
                        }
                      />
                      {errors[`road`] && (
                        <span className="loovi-msg-error">Insira a rua</span>
                      )}
                    </label>
                    <label>
                      <span>NÚMERO</span>
                      <input
                        type="text"
                        name={`number`}
                        onChange={(e) => handleAddressChange(e, index)}
                        ref={register({ required: true })}
                        className={
                          errors[`number`]
                            ? "loovi-field loovi-field-error"
                            : "loovi-field"
                        }
                      />
                      {errors[`number`] && (
                        <span className="loovi-msg-error">Insira o número</span>
                      )}
                    </label>
                    <label>
                      <span>COMPLEMENTO</span>
                      <input
                        type="text"
                        name={`complement`}
                        onChange={(e) => handleAddressChange(e, index)}
                        ref={register({ required: true })}
                        className={
                          errors[`complement`]
                            ? "loovi-field loovi-field-error"
                            : "loovi-field"
                        }
                      />
                      {errors[`complement`] && (
                        <span className="loovi-msg-error">
                          Insira o complemento
                        </span>
                      )}
                    </label>
                  </>
                )}
                {field.registrationData.value === "Telefone" && (
                  <>
                    <h4
                      className="loovi-color-grey-2"
                      style={{ marginTop: "1.2rem", marginBottom: "0.7rem" }}
                    >
                      Informe seu novo número
                    </h4>
                    <label>
                      <span>NÚMERO DE TELEFONE</span>
                      <input
                        type="text"
                        name="newPhone"
                        value={field.newPhone}
                        onChange={(e) => {
                          props.handleChange(e, index);
                          if (e.target.value.length > 0) {
                            setIsSubmitDisabled(false);
                          } else {
                            setIsSubmitDisabled(true);
                          }
                        }}
                        ref={register({
                          required: true,
                          validate: (input) => {
                            const value = input.replace(/[\D]/g, "");
                            return (
                              parseInt(value[2]) === 9 && value.length >= 11
                            );
                          },
                        })}
                        className={
                          errors[`newPhone`]
                            ? "loovi-field loovi-field-error"
                            : "loovi-field"
                        }
                      />
                      {errors[`newPhone`] && (
                        <span className="loovi-msg-error">Insira o número</span>
                      )}
                    </label>
                  </>
                )}
                {field.registrationData.value === "E-mail" && (
                  <>
                    <h4
                      className="loovi-color-grey-2"
                      style={{ marginTop: "1rem" }}
                    >
                      Informe seu novo e-mail
                    </h4>
                    <label>
                      <span>INFORME SEU E-MAIL</span>
                      <input
                        type="text"
                        name="newEmail"
                        value={field.newEmail}
                        onChange={(e) => {
                          props.handleChange(e, index);
                          if (e.target.value.length > 0) {
                            setIsSubmitDisabled(false);
                          } else {
                            setIsSubmitDisabled(true);
                          }
                        }}
                        ref={register({
                          required: true,
                          validate: (input) => {
                            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                            return emailPattern.test(input);
                          },
                        })}
                        className={
                          errors[`newEmail`]
                            ? "loovi-field loovi-field-error"
                            : "loovi-field"
                        }
                      />
                      {errors[`newEmail`] && (
                        <span className="loovi-msg-error">
                          Insira um e-mail válido
                        </span>
                      )}
                    </label>
                  </>
                )}
                {field.registrationData.value === "Utilização do veículo" && (
                  <>
                    <label>
                      <span style={{ marginTop: "1rem" }}>
                        utilizo o carro para:
                      </span>
                      <select
                        name={`vehicleUseCheck`}
                        ref={register({ required: true })}
                        className="loovi-select"
                        value={field.vehicleUse.value}
                        onChange={(e) => {
                          if (e.target.value.length > 0) {
                            setIsSubmitDisabled(false);
                          } else {
                            setIsSubmitDisabled(true);
                          }
                          const val = e.target.value;
                          props.handleSelectChangeVehicle(
                            { value: val, label: val },
                            index
                          );
                          if (field.vehicleUses.value !== "")
                            return clearError(`vehicleUseCheck`);
                        }}
                      >
                        {field.vehicleUses.map((area, idx) => (
                          <option key={idx} value={area.value}>
                            {area.label}
                          </option>
                        ))}
                      </select>
                      {errors[`vehicleUseCheck`] && (
                        <span className="loovi-msg-error">
                          Selecione uma opção
                        </span>
                      )}
                    </label>
                  </>
                )}
                {field.registrationData.value === "Outros" && (
                  <>
                    <label>
                      <span style={{ marginTop: "1rem" }}>
                        DESCREVA SEU CASO
                      </span>
                      <textarea
                        name="anotherMotive"
                        value={field.anotherMotive}
                        onChange={(e) => {
                          props.handleChange(e, index);
                          if (e.target.value.length > 0) {
                            setIsSubmitDisabled(false);
                          } else {
                            setIsSubmitDisabled(true);
                          }
                        }}
                        ref={register({ required: true })}
                        className={
                          errors.anotherMotive
                            ? "loovi-field loovi-field-error"
                            : "loovi-field"
                        }
                      />
                      {errors.anotherMotive && (
                        <span className="loovi-msg-error">Insira o motivo</span>
                      )}
                    </label>
                  </>
                )}
              </div>
            )}
          </>
        ))}
        <label className="checkbox">
          <input
            type="checkbox"
            checked={props.values.showAdditionalField}
            onChange={props.handleCheckboxChange}
          />
          <span>Alterar mais dados</span>
        </label>
        {props.values.showAdditionalField && (
          <>
            {props.values.fields.map((field, index) => (
              <>
                {index === 1 && (
                  <div key={index}>
                    <label>
                      <span>O QUE DESEJA ALTERAR?</span>
                      <select
                        name={`registrationDataCheck`}
                        ref={register({ required: true })}
                        className="loovi-select"
                        value={field.registrationData.value}
                        onChange={(e) => {
                          const val = e.target.value;
                          props.handleSelectChangeData(
                            { value: val, label: val },
                            index
                          );
                          if (field.registrationData.value !== "")
                            return clearError(`registrationDataCheck`);
                        }}
                      >
                        {field.registrationsData.map((area, idx) => (
                          <option key={idx} value={area.value}>
                            {area.label}
                          </option>
                        ))}
                      </select>
                      {errors[`registrationDataCheck`] && (
                        <span className="loovi-msg-error">
                          Selecione uma opção
                        </span>
                      )}
                    </label>
                    {field.registrationData.value === "Endereço" && (
                      <>
                        <h4 className="loovi-color-grey-2">
                          Informe seu novo endereço
                        </h4>
                        <div className="address-cep">
                          <label>
                            <span>CEP</span>
                            <input
                              type="text"
                              name={`zipCode`}
                              onChange={(e) =>
                                props.handleChangeAddress(e, index)
                              }
                              ref={register({
                                required: true,
                                validate: (input) => {
                                  const value = input.replace(/[\D]/g, "");
                                  return value.replace(/\d{5}-\d{3}/);
                                },
                              })}
                              onBlur={(e) => load(e.target.value, index)}
                              className={
                                errors[`zipCode`]
                                  ? "loovi-field loovi-field-error"
                                  : "loovi-field"
                              }
                              maxLength="9"
                            />
                            {errors[`zipCode`] && (
                              <span className="loovi-msg-error">
                                Insira o CEP
                              </span>
                            )}
                          </label>
                          <label>
                            <span>ESTADO</span>
                            <input
                              type="text"
                              name={`state`}
                              value={field.addressFields.state}
                              onChange={(e) =>
                                props.handleChangeAddress(e, index)
                              }
                              ref={register({ required: true })}
                              className={
                                errors[`state`]
                                  ? "loovi-field loovi-field-error"
                                  : "loovi-field"
                              }
                            />
                            {errors[`state`] && (
                              <span className="loovi-msg-error">
                                Insira o estado
                              </span>
                            )}
                          </label>
                        </div>
                        <label>
                          <span>CIDADE</span>
                          <input
                            type="text"
                            name={`city`}
                            value={field.addressFields.city}
                            onChange={(e) =>
                              props.handleChangeAddress(e, index)
                            }
                            ref={register({ required: true })}
                            className={
                              errors[`city`]
                                ? "loovi-field loovi-field-error"
                                : "loovi-field"
                            }
                          />
                          {errors[`city`] && (
                            <span className="loovi-msg-error">
                              Insira a cidade
                            </span>
                          )}
                        </label>
                        <label>
                          <span>BAIRRO</span>
                          <input
                            type="text"
                            name={`neighborhood`}
                            value={field.addressFields.neighborhood}
                            onChange={(e) =>
                              props.handleChangeAddress(e, index)
                            }
                            ref={register({ required: true })}
                            className={
                              errors[`neighborhood`]
                                ? "loovi-field loovi-field-error"
                                : "loovi-field"
                            }
                          />
                          {errors[`neighborhood`] && (
                            <span className="loovi-msg-error">
                              Insira o bairro
                            </span>
                          )}
                        </label>
                        <label>
                          <span>RUA</span>
                          <input
                            type="text"
                            name={`road`}
                            value={field.addressFields.road}
                            onChange={(e) =>
                              props.handleChangeAddress(e, index)
                            }
                            ref={register({ required: true })}
                            className={
                              errors[`road`]
                                ? "loovi-field loovi-field-error"
                                : "loovi-field"
                            }
                          />
                          {errors[`road`] && (
                            <span className="loovi-msg-error">
                              Insira a rua
                            </span>
                          )}
                        </label>
                        <label>
                          <span>NÚMERO</span>
                          <input
                            type="text"
                            name={`number`}
                            onChange={(e) =>
                              props.handleChangeAddress(e, index)
                            }
                            ref={register({ required: true })}
                            className={
                              errors[`number`]
                                ? "loovi-field loovi-field-error"
                                : "loovi-field"
                            }
                          />
                          {errors[`number`] && (
                            <span className="loovi-msg-error">
                              Insira o número
                            </span>
                          )}
                        </label>
                        <label>
                          <span>COMPLEMENTO</span>
                          <input
                            type="text"
                            name={`complement`}
                            onChange={(e) =>
                              props.handleChangeAddress(e, index)
                            }
                            ref={register({ required: true })}
                            className={
                              errors[`complement`]
                                ? "loovi-field loovi-field-error"
                                : "loovi-field"
                            }
                          />
                          {errors[`complement`] && (
                            <span className="loovi-msg-error">
                              Insira o complemento
                            </span>
                          )}
                        </label>
                      </>
                    )}
                    {field.registrationData.value === "Telefone" && (
                      <>
                        <h4 className="loovi-color-grey-2">
                          Informe seu novo número
                        </h4>
                        <label>
                          <span>NÚMERO DE TELEFONE</span>
                          <input
                            type="text"
                            name="newPhoneField02"
                            value={field.newPhone}
                            onChange={(e) => {
                              props.handleChange(e, index);
                              if (e.target.value.length > 0) {
                                setIsSubmitDisabled(false);
                              } else {
                                setIsSubmitDisabled(true);
                              }
                            }}
                            ref={register({
                              required: true,
                              validate: (input) => {
                                const value = input.replace(/[\D]/g, "");
                                return (
                                  parseInt(value[2]) === 9 && value.length >= 11
                                );
                              },
                            })}
                            className={
                              errors[`newPhoneField02`]
                                ? "loovi-field loovi-field-error"
                                : "loovi-field"
                            }
                          />
                          {errors[`newPhoneField02`] && (
                            <span className="loovi-msg-error">
                              Insira o número
                            </span>
                          )}
                        </label>
                      </>
                    )}
                    {field.registrationData.value === "E-mail" && (
                      <>
                        <h4 className="loovi-color-grey-2">
                          Informe seu novo e-mail
                        </h4>
                        <label>
                          <span>INFORME SEU E-MAIL</span>
                          <input
                            type="text"
                            name="newEmailField02"
                            value={field.newEmail}
                            onChange={(e) => props.handleChange(e, index)}
                            ref={register({
                              required: true,
                              validate: (input) => {
                                const emailPattern =
                                  /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                return emailPattern.test(input);
                              },
                            })}
                            className={
                              errors[`newEmailField02`]
                                ? "loovi-field loovi-field-error"
                                : "loovi-field"
                            }
                          />
                          {errors[`newEmailField02`] && (
                            <span className="loovi-msg-error">
                              Insira um e-mail válido
                            </span>
                          )}
                        </label>
                      </>
                    )}
                    {field.registrationData.value ===
                      "Utilização do veículo" && (
                      <>
                        <label>
                          <span>utilizo o carro para:</span>
                          <select
                            name={`vehicleUseCheck`}
                            ref={register({ required: true })}
                            className="loovi-select"
                            value={field.vehicleUse.value}
                            onChange={(e) => {
                              const val = e.target.value;
                              props.handleSelectChangeVehicle(
                                { value: val, label: val },
                                index
                              );
                              if (field.vehicleUses.value !== "")
                                return clearError(`vehicleUseCheck`);
                            }}
                          >
                            {field.vehicleUses.map((area, idx) => (
                              <option key={idx} value={area.value}>
                                {area.label}
                              </option>
                            ))}
                          </select>
                          {errors[`vehicleUseCheck`] && (
                            <span className="loovi-msg-error">
                              Selecione uma opção
                            </span>
                          )}
                        </label>
                      </>
                    )}
                    {field.registrationData.value === "Outros" && (
                      <>
                        <label>
                          <span>DESCREVA SEU CASO</span>
                          <textarea
                            name="anotherMotive"
                            value={field.anotherMotive}
                            onChange={(e) => props.handleChange(e, index)}
                            ref={register({ required: true })}
                            className={
                              errors.anotherMotive
                                ? "loovi-field loovi-field-error"
                                : "loovi-field"
                            }
                          />
                          {errors.anotherMotive && (
                            <span className="loovi-msg-error">
                              Insira o motivo
                            </span>
                          )}
                        </label>
                      </>
                    )}
                  </div>
                )}
              </>
            ))}
          </>
        )}
        {props.values.fields[0].registrationData.value === "Outros" ? (
          <input
            type="submit"
            value="Enviar solicitação"
            disabled={isSubmitDisabled}
          />
        ) : (
          <input
            type="submit"
            value="Confirmar alteração"
            disabled={isSubmitDisabled}
          />
        )}
      </form>
    </React.Fragment>
  );
};

export class FormUpdateData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      showAdditionalField: false,
      fields: [
        {
          registrationsData: [
            { value: "", label: "-- Nenhum --" },
            { value: "Endereço", label: "Endereço" },
            { value: "Telefone", label: "Telefone" },
            { value: "E-mail", label: "E-mail" },
            { value: "Utilização do veículo", label: "Utilização do veículo" },
            { value: "Outros", label: "Outros" },
          ],
          registrationData: { value: "", label: "-- Nenhum --" },
          addressFields: {
            zipCode: "",
            city: "",
            state: "",
            road: "",
            number: "",
            complement: "",
            neighborhood: "",
          },
          newPhone: "",
          newEmail: "",
          anotherMotive: "",
          vehicleUse: { value: "", label: "-- Nenhum --" },
          vehicleUses: [
            { value: "", label: "-- Nenhum --" },
            { value: "Fins Comerciais", label: "Fins Comerciais" },
            { value: "Veículo de Passeio", label: "Veículo de Passeio" },
          ],
        },
        {
          registrationsData: [
            { value: "", label: "-- Nenhum --" },
            { value: "Endereço", label: "Endereço" },
            { value: "Telefone", label: "Telefone" },
            { value: "E-mail", label: "E-mail" },
            { value: "Utilização do veículo", label: "Utilização do veículo" },
            { value: "Outros", label: "Outros" },
          ],
          registrationData: { value: "", label: "-- Nenhum --" },
          addressFields: {
            zipCode: "",
            neighborhood: "",
            city: "",
            state: "",
            road: "",
            number: "",
            complement: "",
          },
          newPhone: "",
          newEmail: "",
          anotherMotive: "",
          vehicleUse: { value: "", label: "-- Nenhum --" },
          vehicleUses: [
            { value: "", label: "-- Nenhum --" },
            { value: "Fins Comerciais", label: "Fins Comerciais" },
            { value: "Veículo de Passeio", label: "Veículo de Passeio" },
          ],
        },
      ],
    };
  }

  proceed = () => {
    this.setState({ step: 2 });
  };

  handleSelectChangeData = (selectedOption, index) => {
    this.setState((prevState) => {
      const updatedFields = [...prevState.fields];
      updatedFields[index].registrationData = selectedOption;
      return { fields: updatedFields };
    });

    if (index === 0) {
      this.setState((prevState) => {
        const updatedFields = [...prevState.fields];
        updatedFields[0].registrationData = selectedOption;

        const filteredOptions = prevState.fields[0].registrationsData.filter(
          (option) => option.value !== selectedOption.value
        );

        updatedFields[1].registrationsData = filteredOptions;

        return { fields: updatedFields };
      });
    }
  };

  handleChangeAddress = (event, index) => {
    const { name, value } = event.target;
    this.setState((prevState) => {
      const updatedFields = [...prevState.fields];

      if (name === "zipCode") {
        const zipCodeValue = value.replace(/\D/g, "");
        const formattedValue = zipCodeValue.replace(
          /^(\d{5})(\d{3})$/,
          "$1-$2"
        );

        updatedFields[index] = {
          ...updatedFields[index],
          addressFields: {
            ...updatedFields[index].addressFields,
            [name]: formattedValue,
          },
        };
      } else {
        updatedFields[index] = {
          ...updatedFields[index],
          addressFields: {
            ...updatedFields[index].addressFields,
            [name]: value,
          },
        };
      }
      return { fields: updatedFields };
    });
  };

  handleChange = (event, index) => {
    const { name, value } = event.target;

    this.setState((prevState) => {
      const updatedFields = [...prevState.fields];
      const fieldToUpdate = updatedFields[index];
      if (name === "newPhone") {
        let sanitizedValue = value.replace(/[\D]/g, "");
        const maskedValue = masks.phone(sanitizedValue);
        fieldToUpdate.newPhone = maskedValue;
      } else if (name === "newEmail") {
        fieldToUpdate.newEmail = value;
      } else if (name === "anotherMotive") {
        fieldToUpdate.anotherMotive = value;
      }

      return { fields: updatedFields };
    });
  };

  handleSelectChangeVehicle = (selectedOption, index) => {
    this.setState((prevState) => {
      const updatedFields = [...prevState.fields];
      updatedFields[index].vehicleUse = selectedOption;
      return { fields: updatedFields };
    });
  };

  handleCheckboxChange = () => {
    this.setState((prevState) => ({
      showAdditionalField: !prevState.showAdditionalField,
    }));
  };

  render() {
    const { step, showAdditionalField, fields } = this.state;
    const { contactPhone, setLoading, proceed } = this.props;
    const values = {
      step,
      showAdditionalField,
      fields,
      contactPhone,
      setLoading,
    };
    return (
      <Section>
        <Form
          values={values}
          proceed={proceed}
          handleSelectChangeData={this.handleSelectChangeData}
          handleCheckboxChange={this.handleCheckboxChange}
          handleChange={this.handleChange}
          handleChangeAddress={this.handleChangeAddress}
          handleSelectChangeVehicle={this.handleSelectChangeVehicle}
        />
      </Section>
    );
  }
}

const Section = styled.section`
  position: relative;
  font-size: 15px;

  p {
    margin: 0;
    font-size: 16px;
    letter-spacing: -0.32px;
    line-height: 21px;
  }

  h4 {
    font-size: 15px;
    color: #899299;
    letter-spacing: -0.32px;
    text-align: center;
    line-height: 21px;
  }

  .loovi-descktop-padding {
    h3,
    h4 {
      text-align: left;
    }
  }

  .address-cep {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
  }

  .address-cep input {
    width: 96% !important;
  }

  .checkbox {
    color: #a1a4ab !important;
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }

  .checkbox span {
    text-transform: none !important;
  }
`;
