import React, { useEffect, useRef, useState } from "react";
import * as masks from "../../helpers/masks";
import useForm from "react-hook-form";
import Lockr from "lockr";

import { Container, Row, Col } from "react-bootstrap";
import { MobileView, BrowserView } from "react-device-detect";
import { URLS, api_auth, api_keyed } from "../../helpers/Request";

import Header from "../Header";
import NavBar from "./NavBar";
import Sidebar from "./Sidebar";
import LoaderAnimation from "../LoaderAnimation";
import { SinisterStep2 } from "../SinisterStep2";

import styled from "styled-components";

const ENVIRONMENT = process.env.REACT_APP_BASE_ENVIRONMENT;
const REASONS_OPTIONS = [
  { value: "Colisão", label: "Colisão" },
  { value: "Vidros", label: "Vidros" }
];

const SUB_REASONS_OPTIONS = [
  { value: "", label: "-- Nenhum --" },
  { value: "Danos da natureza", label: "Danos da natureza" },
  { value: "Incêndio", label: "Incêndio" }
];

const YES_NO_OPTIONS = [
  { value: "", label: "-- Nenhum --" },
  { value: "Sim", label: "Sim" },
  { value: "Não", label: "Não" },
];

const ORIGIN_ID = {
  production: "00D4P0000010vdU",
  staging: "00D2C0000008keR",
  development: "00D790000008hiR",
};

const CASE_REASON = {
  production: "reason",
  staging: "reason",
  development: "reason",
};

const CASE_CONTRACTINFO = {
  production: "00N6f00000Febwz",
  staging: "00N6f00000Febwz",
  development: "00N6f00000Febwz",
};

const CASE_DESCRIPTION = {
  production: "00N8Z00000FLx8N",
  staging: "00N2C000002LObb",
  development: "00N79000000vDgO",
};


const CASE_LOCATION = {
  production: "00N6f00000Febyj",
  staging: "00N6f00000Febyj",
  development: "00N6f00000Febyj",
};

const CASE_REFERENCEPOINT = {
  production: "00N8Z00000FLx8f",
  staging: "00N2C000002LObs",
  development: "00N79000000vMS1",
};

const CASE_TIMEOFOCCURENCE = {
  production: "00N8Z00000FLx8J",
  staging: "00N2C000002LObX",
  development: "00N79000000vDfk",
};

const CASE_DRIVER = {
  production: "00N8Z00000FLx8C",
  staging: "00N2C000002LObN",
  development: "00N79000000vKeo",
};

const CASE_DRIVERCPF = {
  production: "00N8Z00000FLx86",
  staging: "00N2C000002LObH",
  development: "00N79000000vMWN",
};

const CASE_DRIVERCONTACT = {
  production: "00N8Z00000FLx8F",
  staging: "00N2C000002LObS",
  development: "00N79000000vMWX",
};

const CASE_ISVEHICLEMOVING = {
  production: "00N8Z00000FLx8t",
  staging: "00N2C000002LOc5",
  development: "00N79000000vZuh",
};

const CASE_INCIDENTREPORTCARRIEDOUT = {
  production: "00N8Z00000FLx84",
  staging: "00N2C000002LObB",
  development: "00N79000000vDgx",
};

const CASE_INVOLVEMENTWITHTHIRDPARTIES = {
  production: "00N8Z00000FLx8R",
  staging: "00N2C000002LObe",
  development: "00N79000000vDgJ",
};

const CASE_THIRDPARTYCONTACT = {
  production: "00N8Z00000FLx8G",
  staging: "00N2C000002LObT",
  development: "00N8Z00000FLx8G",
};

const SUB_CASE_REASON = {
  production: "00N88000001cMAD",
  staging: "00N88000001cMAD",
  development: "00N88000001cMAD",
};



const STEPS = {
  STEP_1: 0,
  STEP_2: 1,
};

const updatedClientData = (nCtrId, clientData) => {
  const value = clientData.customerCars.filter(
    (contract) => contract.contractInfo.nCtrId === nCtrId
  );

  if (value.length > 0) {
    Lockr.set("ClientData", {
      IdCustomerSelectedCar: value[0].value,
      customerCars: clientData.customerCars,
      customerSelectedCar: value[0],
      customerInfo: clientData.customerInfo,
    });
  }

  return Lockr.get("ClientData");
};

const delay = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);

export default function Sinisters(props) {
  const [isLoading, setLoading] = useState(false);
  const [chamado, setChamado] = useState("");
  const [selectedImageBase64, setSelectedImageBase64] = useState("");
  const [isNotPermission, setIsNotPermission] = useState(false)
  const [typeOfContract, setTypeOfContract] = useState("")
  const [state, setState] = useState({
    step: STEPS.STEP_1 ,
    reason: { value: "" , label: ""},
    subReason: { value: "", label: "-- Nenhum --" },
    description: "",
    location: "",
    referencePoint: "",
    date: "",
    time: "",
    driver: "",
    driverCPF: "",
    driverContact: "",
    isVehicleMoving: { value: "", label: "-- Nenhum --" },
    currentVehicleLocation:"",
    incidentReportCarriedOut: { value: "", label: "-- Nenhum --" },
    involvementWithThirdParties: { value: "", label: "-- Nenhum --" },
    isOpenProtocolToThirdParties:{ value: "", label: "-- Nenhum --" },
    thirdPartyContact:"",
    chamado: "",
  });

  const fetchClientData = (async() =>{
    try {
      setLoading(true)
        let clientData = await Lockr.get("ClientData");

        if (props.match.params.nCtrId) {
          clientData = updatedClientData(props.match.params.nCtrId, clientData);
        }

        const result =  await api_keyed.get(
          `${URLS.SALES_FORCE}/consultas/casos_app_por_contrato/${clientData.customerSelectedCar.contractInfo.nCtrId}`
        ).json();

        const findChamado = result.find((elment)=> elment.Status === "New" && (elment.Reason === "Colisão" || elment.Reason === "Vidros"))

        if(findChamado){
          setChamado(findChamado.CaseNumber);
          setState((prevstate) => ({ ...prevstate, chamado: findChamado.Reason }));

          try {
            const resultFotos = await api_auth
            .get(
              `${URLS.FOTOS}/status/${clientData.customerSelectedCar.contractInfo.nCtrId}/${findChamado.CaseNumber}`
            ).json();

            if (
              (resultFotos.Status !== "todas-pendente-upload" &&
              resultFotos.Status !== "alguma-pendente-aprovacao" &&
              resultFotos.Status !== "alguma-reprovada" &&
              resultFotos.Status !== "alguma-pendente-upload" )|| resultFotos.Status === "alguma-pendente-aprovacao"
            ) {
              alert("Você já possui um sinistro aberto!");
              window.location.href = `/consulta/${findChamado.CaseNumber}`
              
            }else{
              setState((prevstate) => ({ ...prevstate, step: STEPS.STEP_2 }));
            }
          } catch (err) {
            console.error("Error ao buscar status das Fotos!", err)
          }
        }
        
      } catch (err) {
        setIsNotPermission(true)
        console.error(err)
      }
    setLoading(false);
  })

  const onCheckFind = async()=>{
    let numAttempts = 0
    const intervalId = setInterval(async () => {
      try {
        let clientData = await Lockr.get("ClientData");

        const result =    await api_keyed.get(
          `${URLS.SALES_FORCE}/consultas/casos_app_por_contrato/${clientData.customerSelectedCar.contractInfo.nCtrId}`
        ).json();
    
        const findChamado = result.find((elment)=> elment.Status === "New" && (elment.Reason === "Colisão" || elment.Reason === "Vidros"))

        if (findChamado) {
          setChamado(findChamado.CaseNumber);
          setState((prevstate) => ({ ...prevstate, chamado: findChamado.Reason, step: STEPS.STEP_2  }));
          clearInterval(intervalId)
          setLoading(false)
        } else if(numAttempts  < 6) {
          numAttempts++
        }

        if (numAttempts === 6) {
          alert("Não foi possível criar o sinistro. Por favor, tente mais tarde!")
          clearInterval(intervalId)
          setLoading(false)
          window.location.href = "/dashboard"
        }
        

      } catch (error) {
        console.error('Erro ao consultar chamado!', error);
        setLoading(false)
        clearInterval(intervalId);
      }
    }, 5000);
  }

  const fireWebToCase = async (formData) => {
    const clientData = Lockr.get("ClientData");
    const [year, month, day] = formData.date.split('-')
    const formattedDate = `${day}/${month}/${year}`;

    const payload = { 
      orgid: ORIGIN_ID[ENVIRONMENT],
      encoding: "UTF-8",
      [CASE_CONTRACTINFO[ENVIRONMENT]]: clientData.customerSelectedCar.contractInfo.nCtrId,
      [CASE_TIMEOFOCCURENCE[ENVIRONMENT]]: `${formattedDate} ${formData.time}`,
      [CASE_DRIVERCONTACT[ENVIRONMENT]]: formData.driverContact,
      [CASE_DRIVERCPF[ENVIRONMENT]]: formData.driverCPF,
      [CASE_DRIVER[ENVIRONMENT]]: formData.driver,
      [CASE_INVOLVEMENTWITHTHIRDPARTIES[ENVIRONMENT]]: formData.involvementWithThirdParties,
      [CASE_REFERENCEPOINT[ENVIRONMENT]]: formData.referencePoint,
      [CASE_DESCRIPTION[ENVIRONMENT]]: `${formData.description} ${formData.currentVehicleLocation ? "/Local do veículo: " + formData.currentVehicleLocation : ""} `,
      [CASE_LOCATION[ENVIRONMENT]]: formData.location,
      [SUB_CASE_REASON[ENVIRONMENT]]: formData.subReason,
      [CASE_ISVEHICLEMOVING[ENVIRONMENT]]: formData.isVehicleMoving,
      [CASE_REASON[ENVIRONMENT]]: formData.reason,
      [CASE_THIRDPARTYCONTACT[ENVIRONMENT]]: formData.thirdPartyContact ? formData.thirdPartyContact : "",
      [CASE_INCIDENTREPORTCARRIEDOUT[ENVIRONMENT]]: formData.incidentReportCarriedOut,
      submit: "Enviar",
    };


    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");

    const result = await api_auth
      .post(`${URLS.WEBTOCASE}`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      })
      .json()
      .catch((err) => {
        console.log("ERRO AO ENVIAR WEB TO CASE.");
        console.log(err.message)
        alert("Ops! ocorreu um erro, tente novamente mais tarde.");
    });



    if (result && result.success) {
      await onCheckFind()
      return true;
    } else {
      return false;
    }

  };

  const onSelectChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setState({ ...state, [name]: { value, label: value } });
  };

  const onChangeInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setState({ ...state, [name]: value });
  };


  const onSubmit = async (data) => {
    try{
      setLoading(true)
      await fireWebToCase(data)
      
    }catch(err){
      console.error("Error ao consultar o fireWebToCase!", err)
    }
  };

  const onSubmitImage = async (data) => {
    setLoading(true);
    if (selectedImageBase64 !== "") {
      // Send image
      const clientData = Lockr.get("ClientData");
      // TODO: Adicionar caso correto e tipo da imagem
      const result = await api_auth.put(
        `${URLS.FOTOS}/upload/${clientData.customerSelectedCar.contractInfo.nCtrId}/webp`,
        {
          body: selectedImageBase64,
        }
      );
      console.log(result.json());
    }
  };

  useEffect(()=>{
    const clientData = Lockr.get("ClientData");
    const salesContract = clientData.customerSelectedCar.contractInfo.salesContractItems

    const findSeguros_LTI = salesContract.find(contract => contract.itemCode === "SRV_SEGUROS_LTI")
    const findColisaoContract = findSeguros_LTI?.subItems.find(element => element.itemCode === "SRV_SEG_COLISAO")

    const findVidrosContract = salesContract.find(contract => contract.itemCode === "SRV_VIDROS")

    if(!!findColisaoContract && !!findVidrosContract){
      setState((prevState)=>{
        return {...prevState, reason: { value: "Colisão" , label: "Colisão"} }
      })
      setTypeOfContract("Colisão/Vidros")
    }else if(!!findColisaoContract){
      setState((prevState)=>{
        return {...prevState, reason: { value: "Colisão" , label: "Colisão"} }
      })
      setTypeOfContract("Colisão")
    }else if(!!findVidrosContract){
      setState((prevState)=>{
        return {...prevState, reason: { value: "Vidros" , label: "Vidros"} }
      })
      setTypeOfContract("Vidros")
    }else{
      setIsNotPermission(true)
    }

    fetchClientData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  return (
    <Section>
      <Header>
        <NavBar backButton blueArrow />
      </Header>
      <MobileView>
        <Container>
          <Row>
            <Col sm={12} md={{ span: 6, offset: 3 }}>
               {state.step === STEPS.STEP_1 ? <Form
                state={state}
                selectedImagePreview={selectedImageBase64}
                isLoading={isLoading}
                onChangeInput={onChangeInput}
                onChangeSelectedImage={setSelectedImageBase64}
                onSelectChange={onSelectChange}
                onSubmitImage={onSubmitImage}
                onSubmit={onSubmit}
                chamado={chamado}
                isNotPermission={isNotPermission}
                typeOfContract={typeOfContract}
              /> 
              :(
                <React.Fragment>
                  <LoaderAnimation show={isLoading} />
                  <SinisterStep2 chamado={chamado} isIncidentReport={state.incidentReportCarriedOut} />
                </React.Fragment>
              )}
            </Col>
          </Row>
        </Container>
      </MobileView>
      <BrowserView>
        <Container fluid>
          <Row>
            <div className="loovi-fixed-side-column">
              <Sidebar />
            </div>
            <div className="loovi-fluid-column">
              <Col sm={12} md={6}>
                <div className="loovi-descktop-padding">
                {state.step === STEPS.STEP_1 ? <Form
                state={state}
                selectedImagePreview={selectedImageBase64}
                isLoading={isLoading}
                onChangeInput={onChangeInput}
                onChangeSelectedImage={setSelectedImageBase64}
                onSelectChange={onSelectChange}
                onSubmitImage={onSubmitImage}
                onSubmit={onSubmit}
                chamado={chamado}
                isNotPermission={isNotPermission}
                typeOfContract={typeOfContract}
              /> 
              :(
                <React.Fragment>
                  <LoaderAnimation show={isLoading} />
                  <SinisterStep2 chamado={chamado} isIncidentReport={state.incidentReportCarriedOut} />
                </React.Fragment>
              )}
                </div>
              </Col>
            </div>
          </Row>
        </Container>
      </BrowserView>

      {
        isNotPermission && <ModalNotPermission>
            <div>
              <h4>Não é possível criar sinistros</h4>
              <p>No momento você não possui autorização para criar sinistros. Mude seu plano e tente novamente.</p>

              <a href="/dashboard" >Voltar</a>
            </div>
        </ModalNotPermission>
      }
    </Section>
  );
}

function Form({
  state,
  selectedImagePreview,
  isLoading,
  onChangeInput,
  onChangeSelectedImage,
  onSelectChange,
  onSubmitImage,
  onSubmit,
  chamado,
  isNotPermission,
  typeOfContract

}) {
  const { register, handleSubmit, errors, clearError, setValue } =
    useForm();

     const [isCompletForm, setIsCompletForm] = useState(false)

    const InputRef = useRef(null) 

    useEffect(()=>{
      //change the color of the submit button
      const {
        reason, location, description, referencePoint, dateAndTime,
        driver, driverCPF, driverContact , isVehicleMoving,
        currentVehicleLocation, incidentReportCarriedOut,
        involvementWithThirdParties, isOpenProtocolToThirdParties,
        thirdPartyContact
      } = state

      const objectSelect = {reason: reason.value ,
        incidentReportCarriedOut: incidentReportCarriedOut.value,
        involvementWithThirdParties:involvementWithThirdParties.value,
        ...(involvementWithThirdParties.value === "Sim" && {isOpenProtocolToThirdParties: isOpenProtocolToThirdParties.value}),
      }

      const isCompletForm1 = Object.values({
        ...objectSelect,
        location,
        description,
        referencePoint,
        dateAndTime,
        driver,
        driverCPF,
        driverContact,
        isVehicleMoving,
        ...(involvementWithThirdParties.value === "Sim" && isOpenProtocolToThirdParties.value && {thirdPartyContact}),
        ...(isVehicleMoving.value === "Não" && {currentVehicleLocation}),
      }).every(field => field !== '')

      if(InputRef){
        if(isCompletForm1){
          setIsCompletForm(true)
          InputRef.current.style.backgroundColor = "rgba(90, 120, 255, 1)"
          InputRef.current.style.color = "white"
        }else{
          setIsCompletForm(false)
          InputRef.current.style.backgroundColor = "rgba(213, 213, 213, 1)"
          InputRef.current.style.color = "rgba(81, 81, 81, 1)"
        }
      }

    },[state])

    useEffect(()=>{
      if(InputRef.current){
        if(isCompletForm){
          InputRef.current.style.backgroundColor = "rgba(90, 120, 255, 1)"
          InputRef.current.style.color = "white"
        }else{
          InputRef.current.style.backgroundColor = "rgba(213, 213, 213, 1)"
          InputRef.current.style.color = "rgba(81, 81, 81, 1)"
        }
      }
    },[isCompletForm])

    if(isNotPermission){
      return <div></div>
    }

  
  return (
    <React.Fragment>
      <LoaderAnimation show={isLoading} />
      <h3 className="loovi-header-title loovi-color-4">Sinistros</h3>
      <p className="loovi-color-4">
        Precisa de ajuda? Envie sua solicitação para o time da Loovi que
        retornaremos o mais rápido possível.
      </p>
      <span className="loovi-spacer"></span>
      <h4>Preencha os campos abaixo</h4>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>
          <span>Motivo do caso</span>
          <select
            name="reason"
            ref={register({ required: true })}
            className="loovi-select"
            value={state.reason.value}
            onChange={(e) => {
              onSelectChange(e);
              if (state.reason.value !== "") return clearError("reason");
            }}
          >
            {
              typeOfContract === "Colisão/Vidros"?
              <>
                 <option  value="Colisão">
                    Colisão
                  </option>
                  <option value="Vidros">
                    Vidros
                  </option>
              </> 
              : typeOfContract === "Colisão" ? 
                <option  value="Colisão">
                  Colisão
                </option>
              :
                <option value="Vidros">
                  Vidros
                </option>
            }
          </select>
          {errors.reason && (
            <span className="loovi-msg-error">
              Selecione o motivo do caso
            </span>
          )}
        </label>
        {
          state.reason.value === "Colisão" &&
          <label>
            <span>Sub caso</span>
            <select
              name="subReason"
              ref={register({ required: false })}
              className="loovi-select"
              value={state.subReason.value}
              onChange={(e) => {
                onSelectChange(e);
              }}
            >
              {SUB_REASONS_OPTIONS.map((reason, index) => (
                <option key={index} value={reason.value}>
                  {reason.label}
                </option>
              ))}
            </select>
            {errors.subReason && (
              <span className="loovi-msg-error">
                Selecione o motivo do caso
              </span>
            )}
          </label>
        }
        <label>
          <span>Descrição do ocorrido</span>
          <TextArea
            name="description"
            placeholder="Descreva brevemente o caso ocorrido..."
            value={state.description}
            onChange={onChangeInput}
            ref={register({ required: true })}
            maxLength={400}
            className={
              errors.description
                ? "loovi-field loovi-field-error"
                : "loovi-field"
            }
          >
            {state.description}
          </TextArea>
          {errors.description && (
            <span className="loovi-msg-error">Insira uma descrição</span>
          )}
        </label>
        <label>
          <span>Qual o local do sinistro?</span>
          <Input
            type="text"
            name="location"
            placeholder="Rua X, 123 - XYZ - São Paulo"
            onChange={onChangeInput}
            ref={register({ required: true })}
            className={
              errors.location
                ? "loovi-field loovi-field-error"
                : "loovi-field"
            }
          />
          {errors.location && (
            <span className="loovi-msg-error">
              Insira o local do ocorrido
            </span>
          )}
        </label>
        <label>
          <span>Ponto de referência</span>
          <Input
            type="text"
            name="referencePoint"
            placeholder="Em frente ao supermercado ABC"
            onChange={onChangeInput}
            ref={register({ required: true })}
            className={
              errors.referencePoint
                ? "loovi-field loovi-field-error"
                : "loovi-field"
            }
          />
          {errors.referencePoint && (
            <span className="loovi-msg-error">
              Insira um ponto de referência
            </span>
          )}
        </label>

        <label>
          <span>Data da ocorrência</span>
          <Input
            type="date"
            name="date"
            onChange={(e) => {
              setValue("date", e.target.value);
              onChangeInput(e);
            }}
            ref={register({ required: true })}
            className={
              errors.date
                ? "loovi-field loovi-field-error"
                : "loovi-field"
            }
          />
          {errors.date && (
            <span className="loovi-msg-error">
              Insira a data
            </span>
          )}
        </label>

        <label>
          <span>Hora da ocorrência</span>
          <Input
            type="time"
            name="time"
            onChange={(e) => {
              setValue("time", e.target.value);
              onChangeInput(e);
            }}
            ref={register({ required: true })}
            className={
              errors.time
                ? "loovi-field loovi-field-error"
                : "loovi-field"
            }
          />
          {errors.time && (
            <span className="loovi-msg-error">
              Insira o Horário
            </span>
          )}
        </label>

        <label>
          <span>Condutor</span>
          <Input
            type="text"
            name="driver"
            placeholder="João da Silva"
            onChange={onChangeInput}
            ref={register({ required: true })}
            className={
              errors.driver
                ? "loovi-field loovi-field-error"
                : "loovi-field"
            }
          />
          {errors.driver && (
            <span className="loovi-msg-error">
              Insira o nome do condutor
            </span>
          )}
        </label>
        <label>
          <span>CPF do condutor</span>
          <Input
            type="text"
            name="driverCPF"
            placeholder="111.222.333-44"
            onChange={(e) => {
              const value = masks.cpf(
                e.target.value.replace(/[\D]/g, "")
              );
              setValue("driverCPF", value);
              onChangeInput(e);
            }}
            ref={register({ required: true })}
            className={
              errors.driverCPF
                ? "loovi-field loovi-field-error"
                : "loovi-field"
            }
          />
          {errors.driverCPF && (
            <span className="loovi-msg-error">
              Insira o CPF do condutor
            </span>
          )}
        </label>
        <label>
          <span>Contato do condutor</span>
          <Input
            type="text"
            name="driverContact"
            placeholder="(00) 91234-5678"
            onChange={(e) => {
              const value = masks.phone(
                e.target.value.replace(/[\D]/g, "")
              );
              setValue("driverContact", value);
              onChangeInput(e);
            }}
            ref={register({ required: true })}
            className={
              errors.driverContact
                ? "loovi-field loovi-field-error"
                : "loovi-field"
            }
          />
          {errors.driverContact && (
            <span className="loovi-msg-error">
              Insira o CPF do condutor
            </span>
          )}
        </label>
        <label>
          <span>Veículo está se locomovendo?</span>
          <select
            name="isVehicleMoving"
            ref={register({ required: true })}
            className="loovi-select"
            value={state.isVehicleMoving.value}
            onChange={onSelectChange}
          >
            {YES_NO_OPTIONS.map((isVehicleMoving, index) => (
              <option key={index} value={isVehicleMoving.value}>
                {isVehicleMoving.label}
              </option>
            ))}
          </select>
          {errors.isVehicleMoving && (
            <span className="loovi-msg-error">
              Selecione se o veículo está se locomovendo
            </span>
          )}
        </label>
        {
          state.isVehicleMoving.value === "Não" &&
            <label>
              <span>Onde o veículo se encontra?</span>
              <Input
                type="text"
                name="currentVehicleLocation"
                placeholder="Rua X, 123 - XYZ - São Paulo"
                onChange={onChangeInput}
                ref={register({ required: true })}
                className={
                  errors.currentVehicleLocation
                    ? "loovi-field loovi-field-error"
                    : "loovi-field"
                }
              />
              {errors.currentVehicleLocation && (
                <span className="loovi-msg-error">
                  Insira onde o veículo se encontra
                </span>
              )}
          </label>
        }
        <label>
          <span>Boletim realizado?</span>
          <select
            name="incidentReportCarriedOut"
            ref={register({ required: true })}
            className="loovi-select"
            value={state.incidentReportCarriedOut.value}
            onChange={onSelectChange}
          >
            {YES_NO_OPTIONS.map((incidentReportCarriedOut, index) => (
              <option key={index} value={incidentReportCarriedOut.value}>
                {incidentReportCarriedOut.label}
              </option>
            ))}
          </select>
          {errors.incidentReportCarriedOut && (
            <span className="loovi-msg-error">
              Selecione se o boletim de ocorrência foi realizado
            </span>
          )}
        </label>
        <label>
          <span>Envolvimento com terceiros?</span>
          <select
            name="involvementWithThirdParties"
            ref={register({ required: true })}
            className="loovi-select"
            value={state.involvementWithThirdParties.value}
            onChange={onSelectChange}
          >
            {YES_NO_OPTIONS.map((involvementWithThirdParties, index) => (
              <option
                key={index}
                value={involvementWithThirdParties.value}
              >
                {involvementWithThirdParties.label}
              </option>
            ))}
          </select>
          {errors.involvementWithThirdParties && (
            <span className="loovi-msg-error">
              Selecione se houve envolvimento com terceiros
            </span>
          )}
        </label>
        {
          state.involvementWithThirdParties.value === "Sim" && 
            <label>
              <span>Abrir protocolo para terceiro?</span>
              <select
                name="isOpenProtocolToThirdParties"
                ref={register({ required: true })}
                className="loovi-select"
                value={state.isOpenProtocolToThirdParties.value}
                onChange={onSelectChange}
              >
                {YES_NO_OPTIONS.map((option, index) => (
                  <option
                    key={index}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </select>
              {errors.isOpenProtocolToThirdParties && (
                <span className="loovi-msg-error">
                  Selecione se houve envolvimento com terceiros
                </span>
              )}
          </label>
        }
        {
          state.isOpenProtocolToThirdParties.value === "Sim" &&
          state.involvementWithThirdParties.value === "Sim"&&
            <label>
              <span>Contato do terceiro</span>
              <Input
                type="text"
                name="thirdPartyContact"
                placeholder="(00) 91234-5678"
                onChange={(e) => {
                  const value = masks.phone(
                    e.target.value.replace(/[\D]/g, "")
                  );
                  setValue("thirdPartyContact", value);
                  onChangeInput(e);
                }}
                ref={register({ required: true })}
                className={
                  errors.thirdPartyContact
                    ? "loovi-field loovi-field-error"
                    : "loovi-field"
                }
              />
              {errors.thirdPartyContact && (
                <span className="loovi-msg-error">
                  Insira o CPF do condutor
                </span>
              )}
          </label>
        }
        <input  ref={InputRef}   type="submit" value="Enviar" />
      </form>
    </React.Fragment>
  )
}

const Section = styled.section`
  position: relative;
  font-size: 15px;


  p {
    margin: 0;
    font-size: 16px;
    letter-spacing: -0.32px;
    line-height: 21px;
  }

  h4 {
    font-size: 15px;
    color: #899299;
    letter-spacing: -0.32px;
    text-align: center;
    line-height: 21px;
  }

  .loovi-descktop-padding {
    h3,
    h4 {
      text-align: left;
    }
  }

  form > input[type="submit"]{
    background-color: rgba(90, 120, 255, 1);
    color: white;
    text-transform: capitalize;
  }

  input[type="text"]:focus{
    border-color: #899299;
  }

  form>label>span{
    color: rgba(81, 81, 81, 1);
  }

  input[type="date"], input[type="time"]{
    height: 50px;
    border-radius: 5px;
    border: solid 2px #eff2f7;
    width: 100%;
    padding-left: 8px;
    color: #899299;
    font-size: 15px;
    text-transform: none;
    padding-right: 10px;

    &:focus{
      border-color: #899299;
    }
  }
  
`;

const Input = styled.input`
  ::placeholder {
    color: #c1c1c1;
  }
`;

const TextArea = styled.textarea`
  &:focus{
    border-color: #899299;
  }

  ::placeholder {
    color: #c1c1c1;
  }
`;

const ModalNotPermission = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  width: 100vw;
  height: 100vh;
  background-color: rgba(81, 81, 81, 0.25);

  display: flex;
  align-items: center;
  justify-content: center;

  & > div{
    background-color: white;
    border-radius: 12px;
    padding: 16px;
    width: 90%;
    max-width: 500px;

    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  h4{
    font-size: 16px !important;
    font-weight: 700!important;
    line-height: 19.36px;
    text-align: center;
    color: rgba(81, 81, 81, 1) !important;
  }

  p{
    font-size: 14px;
    color: rgba(161, 164, 171, 1);
  }

  a{
    display: flex;
    align-items: center;
    justify-content: center;
    
    height: 45px;
    width: 100%;
    border-radius: 4px;
    background-color: rgba(90, 120, 255, 1);
    color: white;
  }
`